import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useServer } from "../Server/ServerContext";
import { isPasswordSecure } from "./EditAccount.functions";
import config from "../config";
import { Info } from "@mui/icons-material";

export interface ResetDialogProps {
  open: boolean;
}

export default function ResetDialog(props: ResetDialogProps) {
  const { resetNamespace } = useServer();

  const [displayname, setDisplayname] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const [error, setError] = React.useState("");

  const passwordSecure = React.useMemo(
    () => isPasswordSecure(password),
    [password]
  );

  const handleLogin = async () => {
    setError("");
    if (password !== passwordConfirm)
      return setError("Passwörter stimmen nicht überein");
    const error = await resetNamespace({ displayname, username, password });
    if (error === "") {
      setDisplayname("");
      setUsername("");
      setPassword("");
      setPasswordConfirm("");
    } else setError(error);
  };

  return (
    <Dialog open={props.open} maxWidth="xs" fullWidth>
      <form onClick={(event) => event.preventDefault()}>
        <DialogTitle>Account erstellen</DialogTitle>
        <DialogContent>
          <TextField
            value={displayname}
            onChange={(e) => setDisplayname(e.target.value)}
            label="Anzeigename"
            fullWidth
            margin="normal"
            autoFocus
          />
          <TextField
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            label="Username"
            fullWidth
            margin="normal"
          />
          <TextField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            fullWidth
            type="password"
            autoComplete="new-password"
            margin="normal"
            error={password !== "" && !passwordSecure}
            slotProps={{
              input: {
                endAdornment: passwordSecure ? null : (
                  <Tooltip
                    title={
                      <Typography variant="caption">
                        Das Password ist nicht sicher genug. Es muss mindestens
                        {[
                          config.passwordSecurity.minLength ? (
                            <React.Fragment key={"length"}>
                              <br />
                              {config.passwordSecurity.minLength} Zeichen lang
                              sein
                            </React.Fragment>
                          ) : null,
                          config.passwordSecurity.minNumber ? (
                            <React.Fragment key={"number"}>
                              <br />
                              {config.passwordSecurity.minNumber} Zahlen
                              enthalten
                            </React.Fragment>
                          ) : null,
                          config.passwordSecurity.minSpecial ? (
                            <React.Fragment key={"special"}>
                              <br />
                              {config.passwordSecurity.minSpecial} Sonderzeichen
                              enthalten
                            </React.Fragment>
                          ) : null,
                          config.passwordSecurity.minUpper ? (
                            <React.Fragment key={"upper"}>
                              <br />
                              {config.passwordSecurity.minUpper} Großbuchstaben
                              enthalten
                            </React.Fragment>
                          ) : null,
                        ]}
                      </Typography>
                    }
                  >
                    <Info
                      fontSize="small"
                      color={password ? "error" : "disabled"}
                    />
                  </Tooltip>
                ),
              },
            }}
          />
          <TextField
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            label="Password bestätigen"
            fullWidth
            type="password"
            autoComplete="new-password"
            margin="normal"
            error={password !== passwordConfirm && passwordConfirm !== ""}
          />
          <Typography color="error">{error}</Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type="submit"
            variant="contained"
            onClick={handleLogin}
            fullWidth
            disabled={
              !username ||
              !password ||
              !passwordConfirm ||
              !displayname ||
              !passwordSecure
            }
          >
            Account erstellen
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
