import React from "react";

import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { AccountCircle, Logout } from "@mui/icons-material";

import Login from "./Login";
import { useServer } from "../Server/ServerContext";
import ResetDialog from "./ResetDialog";

function AccountActions() {
  const { loggedIn, logout, resetNamespaceMode } = useServer();

  const ACCOUNT_ACTIONS: {
    name: string;
    icon: React.ReactNode;
    onClick: () => void;
  }[] = React.useMemo(
    () => [
      {
        name: "Logout",
        icon: <Logout />,
        onClick: async () => {
          await logout();
          handleClose();
        },
      },
    ],
    [logout]
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (loggedIn) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="large" onClick={handleMenu} color="inherit">
        <AccountCircle />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {ACCOUNT_ACTIONS.map((action) => (
          <MenuItem
            key={action.name}
            onClick={action.onClick}
            sx={{ minWidth: "150px" }}
          >
            {action.icon}
            <Typography variant="inherit" sx={{ marginLeft: "auto" }}>
              {action.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>

      <Login open={!loggedIn && !resetNamespaceMode} />
      <ResetDialog open={resetNamespaceMode} />
    </>
  );
}

export default AccountActions;
