import { Account } from "../types";

/**
 * Create empty account
 * @returns Empty account
 */
export function createEmptyAccount(): Account {
  return {
    displayname: "",
    username: "",
    pin: null,
    permissions: [],
    lastLoginTime: new Date(),
  };
}
