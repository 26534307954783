import { Box, Button, Tooltip, Typography } from "@mui/material";

import config from "../config";
import { useServer } from "../Server/ServerContext";
function AboManagement() {
  const { namespace, adminEmail } = useServer();
  return (
    <Box display="flex" alignItems="center">
      {config.editAboLink ? (
        <Button
          variant="outlined"
          sx={{ margin: "1rem 0.5rem" }}
          target="_blank"
          rel="noreferrer"
          href={
            config.editAboLink +
            (adminEmail
              ? "?prefilled_email=" + encodeURIComponent(adminEmail)
              : "")
          }
        >
          Abo bearbeiten
        </Button>
      ) : null}
      <Button
        variant="outlined"
        sx={{ margin: "1rem 0.5rem" }}
        target="_blank"
        rel="noreferrer"
        href={config.customerFrontend.replace(
          /{{namespace}}/g,
          namespace.toString()
        )}
      >
        Kunden Buchungsseite
      </Button>
      <Button
        variant="outlined"
        sx={{ margin: "1rem 0.5rem" }}
        target="_blank"
        rel="noreferrer"
        href={config.employeeFrontend.replace(
          /{{namespace}}/g,
          namespace.toString()
        )}
      >
        Mitarbeiterseite
      </Button>
      {/* <Typography
        variant="body2"
        sx={{ margin: (theme) => theme.spacing(1, 0, 1, 0.5) }}
      > */}
      <Tooltip
        title={
          <Typography variant="caption">
            Falls Sie diese Email ändern möchten, kontaktieren Sie bitte den
            Support.
          </Typography>
        }
      >
        <Button
          variant="outlined"
          target="_blank"
          href={"mailto:" + config.contact.email}
        >
          Admin Email: {adminEmail}
        </Button>
      </Tooltip>
      {/* </Typography> */}
    </Box>
  );
}

export default AboManagement;
