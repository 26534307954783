import config from "../config";
import { RequestOptions } from "./GenericTypes";
import { jwt } from "./jwt";

const Server = {
  request: async <T>(
    route: string,
    method: "GET" | "PATCH" | "DELETE" | "POST" | "PUT",
    options?: Partial<RequestOptions>
  ): Promise<T> => {
    try {
      // replace params in url (e.g. /api/employees/:id)
      if (options?.params)
        Object.entries(options.params).forEach(([key, value]) => {
          if (value === undefined) return;
          //@ts-expect-error !!!
          route = route.replace(`:${key}`, stringify(value));
        });

      if (route.includes(":"))
        throw new Error("Missing params in url: " + route);
      // create url from base + route with substituted params
      const uncutBasePath = options?.root || config.mainServer;
      // remove leading slash to avoid voiding base path of serverRoot
      const basePath = uncutBasePath.startsWith("/")
        ? uncutBasePath.slice(1)
        : uncutBasePath;
      const baseURL = config.serverRoot || window.location.origin;
      const url = new URL(
        basePath.endsWith("/") && route.startsWith("/")
          ? basePath.slice(0, -1) + route
          : basePath + route,
        // add trailing slash to keep base path of serverRoot
        baseURL.endsWith("/") ? baseURL : baseURL + "/"
      );
      // add query params to url
      if (options?.query)
        Object.keys(options.query).forEach((key) => {
          if (!options?.query) return;
          const value = options.query[key];
          if (value !== undefined)
            //@ts-expect-error !!!
            url.searchParams.append(key, stringify(value));
        });
      const res = await fetch(url.toString(), {
        method,
        headers: {
          "Content-Type": "application/json",
          ...(jwt ? { authorization: "Bearer " + jwt } : {}),
          ...options?.headers,
        },
        body: options?.body ? JSON.stringify(options.body) : undefined,
        mode: "cors",
      });

      if (res.status >= 400 || !res.ok) throw new Error(await res.text());

      if (res.headers.get("content-type")?.includes("application/json")) {
        return await res.json();
      } else {
        return (await res.text()) as T;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      return e.message;
    }
  },

  get: async <T>(route: string, options?: Partial<RequestOptions>) => {
    return Server.request<T>(route, "GET", { ...options, body: undefined });
  },
  post: async <T>(route: string, options?: Partial<RequestOptions>) => {
    return Server.request<T>(route, "POST", options);
  },
  put: async <T>(route: string, options?: Partial<RequestOptions>) => {
    return Server.request<T>(route, "PUT", options);
  },
  patch: async <T>(route: string, options?: Partial<RequestOptions>) => {
    return Server.request<T>(route, "PATCH", options);
  },
  delete: async <T>(route: string, options?: Partial<RequestOptions>) => {
    return Server.request<T>(route, "DELETE", options);
  },
};

function stringify(value: string | number | boolean): string {
  if (value === null) return "null";
  return value.toString();
}

export default Server;
