import ReactDOM from "react-dom/client";

import { ThemeProvider, createTheme } from "@mui/material";
import { SnackbarProvider } from "notistack";

import { ServerProvider } from "./Server/ServerContext";
import App from "./App";

import "./index.css";

const globalTheme = createTheme({
  palette: {
    primary: {
      main: "#e9c46b",
      contrastText: "#fff",
      light: "#caa874",
      dark: "#9f745a",
    },
    secondary: {
      main: "#FFEFCA",
      dark: "#dfe0df9e",
      light: "#FFF8EA9e",
    },
    text: {
      primary: "#201719",
      disabled: "#DFE0DF",
      secondary: "#5f5f5f",
    },
    background: {
      default: "#fff",
    },
    error: {
      main: "#c0564b",
    },
  },
  shape: {
    borderRadius: 3,
  },
});

const theme = createTheme(
  {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            borderWidth: "2px",
            "&:hover": {
              borderWidth: "2px",
            },
          },
        },
        defaultProps: {
          disableElevation: true,
          disableRipple: true,
        },
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            "&:hover": {
              color: globalTheme.palette.text.primary,
            },
          },
          icon: {
            color: `${globalTheme.palette.text.primary} !important`,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              background: globalTheme.palette.primary.main,
              color: globalTheme.palette.primary.contrastText,
              ":hover": {
                background: globalTheme.palette.primary.main,
                color: globalTheme.palette.primary.contrastText,
              },
            },
            ":hover": {
              background: globalTheme.palette.primary.light,
            },
          },
        },
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: globalTheme.shape.borderRadius,
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 2,
              borderColor: globalTheme.palette.primary.main,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderWidth: 2,
              borderColor: globalTheme.palette.primary.main,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: globalTheme.palette.primary.dark,
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: globalTheme.palette.primary.main,
            "&.Mui-checked": {
              color: globalTheme.palette.primary.main,
            },
            "&.Mui-disabled": {
              color: globalTheme.palette.text.disabled,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          noOptions: {
            color: globalTheme.palette.text.primary,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: globalTheme.palette.text.primary,
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          secondary: {
            color: globalTheme.palette.text.primary,
          },
        },
      },
    },
  },
  globalTheme
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <SnackbarProvider maxSnack={3}>
      <ServerProvider>
        <App />
      </ServerProvider>
    </SnackbarProvider>
  </ThemeProvider>
);
