import { Box } from "@mui/material";

import Header from "./Components/Header";
import AccountActions from "./Components/AccountActions";
import AboManagement from "./Components/AboManagement";
import AccountManagement from "./Components/AccountManagement";

import "./app.css";
import AddAccount from "./Components/AddAccount";

function App() {
  return (
    <Box sx={{ height: "100%" }}>
      <Header title="Admin Panel" endAction={<AccountActions />} />

      <Box
        sx={{
          height: "100%",
          overflow: "auto",
          padding: "2rem 1rem 0 1rem",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <AboManagement />
          <Box sx={{ marginLeft: "auto" }} />
          <AddAccount />
        </Box>

        <AccountManagement />
      </Box>
    </Box>
  );
}

export default App;
