import { decodeToken } from "react-jwt";
import config from "../config";
import { Account, JWTPayload } from "../types";
import Server from "./GenericServer";

export const loadAccounts = async () => {
  const accounts = await Server.get<Account[]>("user", {
    root: config.loginServer,
  });

  return accounts.map((account) => ({
    ...account,
    lastLoginTime: new Date(account.lastLoginTime),
  }));
};

export function decodeJWT(jwtString: string): JWTPayload | null {
  const payload = decodeToken(jwtString) as null;
  return payload || null;
}

export async function resetNamespace(
  token: string,
  account: Pick<Account, "displayname" | "username"> & { password: string }
): Promise<string> {
  try {
    const { displayname, username, password } = account;
    const res = await Server.post<
      | "OK"
      | "Internal server error"
      | "Invalid reset token"
      | "Username already exists"
    >("namespace/reset", {
      root: config.loginServer,
      body: {
        reset_token: token,
        displayname,
        username,
        password,
      },
    });
    if (res === "Username already exists")
      return "Benutzername bereits vergeben";
    if (res !== "OK") return "Fehler beim Erstellen des Accounts";
    window.history.pushState({}, "", window.location.pathname);
    window.location.reload();
    return "";
  } catch (e) {
    return "Fehler beim Erstellen des Accounts";
  }
}

export async function login(
  username: string,
  password: string
): Promise<
  | { error: string; jwt?: string; admin: boolean }
  | { jwt: string; error?: string; admin: boolean }
> {
  try {
    const jwt = await Server.post<string>("login", {
      root: config.loginServer,
      body: {
        username,
        password,
      },
    });
    const account = decodeToken(jwt) as Account;
    if (!account)
      return { error: "Benutzername oder Passwort falsch", admin: false };
    return { jwt, admin: account.permissions.includes("admin") };
  } catch (e) {
    console.error("Login failed", e);
    return { error: "Benutzername oder Passwort falsch", admin: false };
  }
}

export async function getNamespaceInfo(): Promise<{ email: string }> {
  const info = await Server.get<{ email: string }>("user/namespace/info", {
    root: config.loginServer,
  });
  return info;
}
