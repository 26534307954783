import { Account } from "../types";

/**
 * Get account variant from account
 * @param account Account to get variant from
 * @returns Account variant
 */
export function getAccountType(account: Account) {
  // Check if permissions contains "store_account"
  if (account.permissions.includes("store_account")) return "Ladenaccount";
  // Check if permissions contains "admin"
  if (account.permissions.includes("admin")) return "Admin";
  // Fallback
  return "Mitarbeiteraccount";
}

/**
 * Get initials from given name
 * @param name Name to get initials from
 * @returns Initials
 */
export function initials(name: string) {
  return name
    .split(" ")
    .map((word) => word[0])
    .join("")
    .substring(0, 2);
}
