import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useServer } from "../Server/ServerContext";
import config from "../config";

export interface LoginProps {
  open: boolean;
  onClose?: () => void;
}

function Login(props: LoginProps) {
  const { login, loggedIn } = useServer();

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const handleLogin = async (username: string, password: string) => {
    setError("");
    setLoading(true);
    try {
      const error = await login(username, password);
      if (error === "") {
        props.onClose?.();
        setUsername("");
        setPassword("");
      } else setError(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (config.devLogin.username && config.devLogin.password && !loggedIn) {
      setUsername(config.devLogin.username);
      setPassword(config.devLogin.password);
      handleLogin(config.devLogin.username, config.devLogin.password);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="xs" fullWidth>
      <form onClick={(event) => event.preventDefault()}>
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <TextField
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            label="Username"
            fullWidth
            autoComplete="username"
            margin="normal"
            autoFocus
          />
          <TextField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            fullWidth
            autoComplete="current-password"
            type="password"
            margin="normal"
          />
          <Typography color="error">{error}</Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type="submit"
            variant="contained"
            onClick={() => {
              handleLogin(username, password);
            }}
            fullWidth
            disabled={!username || !password}
            loading={loading}
          >
            Anmelden
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default Login;
