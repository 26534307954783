import React from "react";

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

import { useServer } from "../Server/ServerContext";
import { getAccountType, initials } from "./AccountManagement.functions";
import EditAccount from "./EditAccount";

import { Account } from "../types";
import { LoadingButton } from "@mui/lab";

function AccountManagement() {
  const {
    accounts,
    updateAccount,
    user,
    isAdmin,
    deleteAccount: deleteAccountFnc,
  } = useServer();

  const [editAccount, setEditAccount] = React.useState<Account | null>(null);
  const [deleteAccount, setDeleteAccount] = React.useState<Account | null>(
    null
  );

  const adminAmount = React.useMemo(
    () =>
      accounts.filter((account) => account.permissions.includes("admin"))
        .length,
    [accounts]
  );

  return accounts.length ? (
    <>
      <List>
        {accounts.map((account) => (
          <React.Fragment key={account.username}>
            <ListItem disablePadding dense>
              <ListItemButton
                disabled={account.username != user && !isAdmin}
                onClick={() => setEditAccount(account)}
              >
                <ListItemAvatar>
                  <Avatar alt={account.displayname}>
                    {initials(account.displayname)}
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary={account.displayname}
                  secondary={`${getAccountType(
                    account
                  )} - letzte Anmeldung am ${account.lastLoginTime.toLocaleDateString(
                    "de-DE"
                  )}`}
                />

                <IconButton>
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteAccount(account);
                  }}
                  // cant delete last admin
                  // cant delete yourself
                  disabled={
                    (adminAmount <= 1 &&
                      account.permissions.includes("admin")) ||
                    account.username === user
                  }
                >
                  <Delete />
                </IconButton>
              </ListItemButton>
            </ListItem>

            <Divider />
          </React.Fragment>
        ))}
      </List>

      <EditAccount
        account={editAccount}
        updateAccount={async (account, oldUsername) => {
          await updateAccount(account, oldUsername);
          setEditAccount(null);
        }}
        onClose={() => setEditAccount(null)}
        adminAmount={adminAmount}
      />
      <Dialog open={!!deleteAccount} onClose={() => setDeleteAccount(null)}>
        <DialogTitle>Account löschen</DialogTitle>
        <DialogContent>
          Sind Sie sich sicher, dass Sie dieses Account löschen wollen? Diese
          Aktion kann nicht rückgängig gemacht werden!
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteAccount(null)}>Abbrechen</Button>
          <LoadingButton
            onClick={async () => {
              if (deleteAccount) {
                await deleteAccountFnc(deleteAccount);
                setDeleteAccount(null);
              }
            }}
          >
            Löschen
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  ) : user ? (
    <Typography variant="body1" align="center">
      Keine Accounts vorhanden
    </Typography>
  ) : null;
}

export default AccountManagement;
